/*------------------------------------*\
          #SECTION-INVOICES-RECIEPT-ACTIONS
\*------------------------------------*/
.reciept__actions {
  margin-top: var(--space-xlg);
}

.btn__cancel {
  background: var(--coal) !important;
  border-color: var(--coal) !important;
  color: #ffffff !important;
}