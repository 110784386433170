/*------------------------------------*\
  #sidebar style
\*------------------------------------*/
.sidebar {
  height: 100vh;
  width: var(--atmSidebarWidth);
  background-color: #323232;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
}

.sidebar__logo {
  margin: 40px auto;
}
/*------------------------------------*\
  #sidemenu
\*------------------------------------*/
.sidemenu {
  width: var(--atmSidebarWidth);
  flex-grow: 1;
}

.sidemenu__icon {
  margin-right: 16px;
}

/* .sidemenu li:last-child {
  position: absolute;
  bottom: 100px;
} */
