/*------------------------------------*\
  #general style
\*------------------------------------*/
.layout {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  background-color: var(--backgroundLight);
}

.layout__content {
  flex-grow: 1;
  padding: 0px 44px 42px 44px;
  box-sizing: border-box;
  margin-left: var(--atmSidebarWidth);
}
