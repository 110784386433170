  /*------------------------------------*\
          #SECTION-PROFILE
  \*------------------------------------*/

.footer__form {
  text-align: center;
}

.footer__form .btn__save {
  margin-top: 20px;
  padding: 0px 40px;
}