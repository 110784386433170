button.btn__bordered {
	border-radius: 2px;
	color: var(--coal);
	border: 1px solid var(--coal);
}

button.btn__bordered__blue {
	border-radius: 2px;
	color: var(--blueAnt);
	border: 1px solid var(--blueAnt);
}

button.btn__bordered__green {
	border-radius: 2px;
	color: var(--androidGreen);
	border: 1px solid var(--androidGreen);
}

button.btn__bordered:hover {
	color: var(--coal);
	border: 1px solid var(--coal);
}

button.btn__bordered__blue:hover {
	color: var(--blueAnt);
	border: 1px solid var(--blueAnt);
}

button.btn__bordered__green:hover {
	color: var(--androidGreen);
	border: 1px solid var(--androidGreen);
}