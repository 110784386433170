/*------------------------------------*\
        #SECTION-RESET-PASSWORD-FORM
\*------------------------------------*/
.form {
  width: 70%;
  padding: var(--space-lg) var(--space-lg) 0px var(--space-lg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form .form__title {
  color: #000000;
  text-align: center;
  font-size: var(--font-mlg);
  font-family: 'AvenirRoman';
  border: 0;
}

.form .form__subtitle {
  text-align: center;
  margin: 0px 0px 20px 0px;
}

.form__group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form__group .form__link {
  text-decoration: underline;
}

.form__group .form__button {
  align-self: center;
  width: fit-content;
  height: 40px;
  margin: 40px 0px;
  padding: 0px 50px;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
}

.form__group .form__button:hover,
.form__group .form__button:focus {
  background: var(--green-dark-color);
  border-color: var(--green-dark-color);
}

.form__group .form__input {
  height: 40px;
  border-radius: 8px;
  margin-top: 5px;
}

.form__text {
  text-align: center;
}

@media screen and (max-width: 992px) {
  .form {
    width: 80%;
  }
}