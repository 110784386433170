/*------------------------------------*\
          #SECTION-CASHIER-PRINT
\*------------------------------------*/
.actions {
  margin-top: var(--space-lg);
}

.btn__cancel {
  background: var(--coal) !important;
  border-color: var(--coal) !important;
  color: #ffffff !important;
}