/*------------------------------------*\
          #SECTION-UPLOAD-XLSX
\*------------------------------------*/

.hide {
  display: none !important;
}

.upload__btn {
  font-size: 16px;
  background-color: var(--primary);
  color: #FFFFFF;
  font-family: 'AvenirRoman';
  padding: 8px 15px;
  border-radius: 50px;
  transition: all 0.5s;
}

.upload__btn:hover {
  cursor: pointer;
  background-color: var(--greenRyb);
}

