:root {
  --dark-color: #090518;
  --orange-dark-color: #E99B04;
  --orange-color: #FFA800;
  --green-color: #95BF48;
  --green-dark-color: #84AA3F;
  --gray-color: #C4C4C4;

  /** Palette Colors **/
  /** -grays- */
  --white: #FFFFFF;
  --cultered: #F9F9F9;
  --gainsboro: #F2F2F2F2;
  --silver: #c4c4c4;
  --coal: #828282;
  --davysGray: #595959;
  --jet: #323232;
  /** -greens- */
  --androidGreen: #95BF48;
  --greenRyb: #84AA3F;

  /** -blue- */
  --blueAnt: #2D9CDB;

  /** -red- */
  --redError: #EB5757;

  /** Colors by uses case */
  --primary: var(--androidGreen);
  --background: var(--primary);
  --backgroundLight: var(--cultered);

  --textColor: var(--davysGray);
  --textColorStrong: var(--jet);

  --borderColor: var(--silver);
  --orange-dark-color: #e99b04;
  --orange-color: #ffa800;
  --green-color: #95bf48;
  --green-dark-color: #84aa3f;
  --gray-color: #c4c4c4;
  --white: #ffffff;

  --font-xlg: 48px;
  --font-lg: 36px;
  --font-mlg: 24px;
  --font-md: 18px;
  --font-sm: 16px;
  --font-xsm: 14px;

  --radius-xlg: 100px;
  --radius-lg: 50px;
  --radius-md: 20px;
  --radius-sm: 16px;
  --radius-xsm: 8px;

  --atmSidebarWidth: 240px;
  --space-xlg: 50px;
  --space-lg: 30px;
  --space-mlg: 25px;
  --space-md: 15px;
  --space-sm: 10px;
  --space-xsm: 5px;

  --bp-xlg: 1200px;
  --bp-lg: 992px;
  --bp-md: 768px;
  --bp-sm: 600px;
}
@font-face {
  font-family: AvenirBlack;
  src: url("./assets/fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: AvenirBook;
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}
@font-face {
  font-family: AvenirRoman;
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}
body {
  margin: 0;
  padding: 0;
  font-family: AvenirBook;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*------------------------------------*\
  #texts style (Common custom clases
\*------------------------------------*/

.title {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 48px;
  color: var(--textColorStrong);
}

.title-description {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 32px;
  color: var(--textColor);
}

.title--green {
  font-weight: 800;
  font-size: 36px;
  color: var(--androidGreen);
  margin-top: 0;
  margin-bottom: 18px;
}

.subtitle-strong {
  font-size: 20px;
  color: var(--textColorStrong);
}

.subtitle-strong-18 {
  font-size: 18px;
  color: var(--textColorStrong);
}

.text-strong-12 {
  color: var(--textColorStrong);
  font-size: 12px;
  font-weight: bold;
}

.text-strong-14 {
  color: var(--textColorStrong);
  font-size: 14px;
  font-weight: bold;
}

.text-green {
  color: var(--androidGreen);
}

.text-coal {
  color: var(--coal);
}

.text-dark {
  color: var(--jet);
}

.text-blue {
  color: var(--blueAnt);
}

.text-red {
  color: var(--redError);
}

.link-blue, .link-blue:active, .link-blue:visited, .link-blue:hover {
  color: var(--blueAnt);
  text-decoration: none;
}
