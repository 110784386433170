/*------------------------------------*\
          #SECTION-UPLOAD-XLSX
\*------------------------------------*/
button.btn__add {
  padding: 0px;
  margin: 0px;
}
button.btn__add:hover,
button.btn__add:focus {
  background-color: transparent;
}
button.btn__add span {
  font-size: 14px;
  background-color: transparent;
  color: var(--primary);
  font-family: 'AvenirRoman';
  padding: 0px;
  margin: 0px;
}

button.btn__add:hover span {
  cursor: pointer;
  color: var(--greenRyb);
}

span.btn__delete {
	color: var(--coal);
	font-size: 22px;
	margin-top: 4px;
}

.residents__list {
	height: 45px;
	max-height: 45px;
	width: 100%;
	margin: 0px;
	padding: 0px;
	display: grid;
	grid-template-columns: 60% 30% 10%;
	gap: 5px;
}

div.residents__list__resident {
	margin-bottom: 0px;
}

div.residents__list__percent {
	margin-bottom: 0px;
}