.seeker_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px var(--space-lg) 4px;
}

.seeker__input {
  border-radius: 50px !important;
  border-color: #ffffff !important;
  box-shadow: 0px 4px 10px rgba(196, 196, 196, 0.25);
}

.seeker_container .seeker__filter {
  border: #ffffff;
  color: var(--davysGray);
  background-color: #ffffff;
  margin-left: 16px;
  font-size: 20px;
  display: grid;
  place-content: center;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
}

.seeker_container .filter__add {
  background: var(--androidGreen);
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(149, 191, 72, 0.25);
}
.seeker_container .filter__add:hover,
.seeker_container .filter__add:focus {
  background: var(--greenRyb);
  color: #ffffff;
}