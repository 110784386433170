/*------------------------------------*\
          #SECTION-INVOICES-RECIEPT
\*------------------------------------*/

.cancel__text {
  color: var(--redError);
  font-weight: 800;
  font-size: var(--font-xsm);
  text-transform: uppercase;
}

.receipt__number {
  color: var(--androidGreen);
  font-weight: bold;
}