kendo-pdf-document p{
    /* color:red; */
    font-size: 5px;
    font-family: 'Times New Roman', Times, serif;
    text-align: left;
    width: 80%;
    line-height: 15px;
}

.k-pdf-export article{
    border: 1px solid black;
    border-radius: 5pt;
    margin-bottom: 10px;
    padding: 5px;
}

kendo-pdf-document strong{
    font-size: 5px;
    font-weight: bold;
    margin-left: -20px;
}