.back__button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}
.back__button:hover {
	cursor: pointer;
}

.back__button__icon {
	font-size: 24px;
}

.back__button__text {
	margin-left: 10px;
	font-weight: 800;
}