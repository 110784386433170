/*------------------------------------*\
          #SECTION-PROFILE
  \*------------------------------------*/

  .profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .profile__avatar__content {
    width: 180px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .profile .profile__avatar__content label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 40px;
    color: rgba(0, 0, 0, 0.1);
  }
  
  .profile .profile__avatar__content label:hover {
    cursor: pointer;
  }
  
  .profile .profile__avatar__content input {
    display: none;
  }
  
  .profile .profile__avatar {
    margin: 15px 0px;
	}
	
	.profile .avatar__loading {
    filter: blur(2px);
  }
  
  .profile .profile__name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .profile .profile__email {
    font-size: 14px;
    color: var(--coal);
  }
  
  .profile__info {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid rgba(196, 196, 196, 0.2);
  }
  
  .profile__info .profile__info__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 8px 0px;
  }
  
  .profile__info .info__title {
    color: var(--silver);
  }
  
  .profile__info .info__value {
    text-decoration: none !important;
	}
	
