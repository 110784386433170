/*------------------------------------*\
  #general style
\*------------------------------------*/
.cashier {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    background-color: var(--backgroundLight);
  }
  
  .cashier__content {
    flex-grow: 1;
    padding: 0px 44px 42px 44px;
    box-sizing: border-box;
    margin-left: var(--atmSidebarWidth);
 }


 .title {
   font-size: 180px;
 }
   /*------------------------------------*\
  #column style
\*------------------------------------*/

.paid{

position: relative;
width: 108px;
height: 16px;
font-family: Avenir;
font-style: normal;
font-weight: 800;
font-size: 12px;
line-height: 16px;
text-align: initial;

/* verde */

color: #95BF48;
}

.pending{

position: absolute;
width: 108px;
height: 16px;
font-family: Avenir;
font-style: normal;
font-weight: 800;
font-size: 12px;
line-height: 16px;
text-align: left;

/* orange dark */

color: #E99B04;

}

.linkGray{
  color: #595959;
  text-decoration: none;
}