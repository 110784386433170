/*------------------------------------*\
  #ListDetailItem General
\*------------------------------------*/
.listdetail {
  display: flex;
  flex-flow: row;
  margin-bottom: 16px;
}

.listdetail__item {
  margin-right: 8px;
  color: var(--textColor);
  font-size: 16px;
}

.listdetail__item--bold {
  color: var(--textColorStrong);
  font-size: 16px;
}
