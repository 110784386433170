/*------------------------------------*\
          #MAIN-WRAPPER
  \*------------------------------------*/

.mainwrapper {
  display: grid;
	width: 700px;
	max-width: 700px;
	min-width: 700px;
  position: relative;
	padding: 30px;
	font-size: 9px !important;
	font-family: 'Times New Roman' !important;
}

/*------------------------------------*\
          #SECTION-HEADER
   \*------------------------------------*/

.wrapper__header {
  display: grid;
  grid-template-columns: 9% 69% 20%;
	height: auto;
	column-gap: 1%;
  margin-bottom: 10px;
	width: 100%;
}

.logo {
	width: 100%;
  display: grid;
  place-content: center;
	background-image: url('../../assets/images/redLogo.png');
	background-size: 80px;
	background-repeat: no-repeat;
	background-position: center;
	padding: 10px;
}

.header_h1 {
  color:red;
  font-size: 24px;
	font-family: Baskerville;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 2px;
  margin:0 auto;
}

.header p {
  text-align: center;
  font-size: 10px;
  line-height: 5px;
}

.receipt__number {
  display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  border: 1px solid black;
  border-radius: 5pt;
}

.receipt__number div {
  display: grid;
	place-content: center;
}

.receipt__number_field {
  border-top: 1px solid black;
}

/*------------------------------------*\
          #SECTION-USER NAME AND DATE
  \*------------------------------------*/

.wrapper__user_date {
  display: grid;
  grid-template-columns: 79% 20%;
	margin-bottom: 10px;
	column-gap: 1%;
  height: 50px;
}

.user__field {
  display: grid;
  text-align: start;
  border: 1px solid black;
  border-radius: 5pt;
	height: 100%;
	padding: 5px;
}

.user__field p {
  margin: 0px;
}

.date {
	display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  height: 100%;
  border: 1px solid black;
  border-radius: 5pt;
	text-align: center;
}

.date__title {
	border-bottom: 1px solid black;
}
.date__container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
	grid-template-rows: auto;
}

.date__day {
	display: grid;
	place-content: center;
	border-right: 1px solid black;
}

.date__month {
  display: grid;
	place-content: center;
	border-right: 1px solid black;
}

.date__year {
  display: grid;
	place-content: center;
}

/*------------------------------------*\
          #SECTION-BATCHES DATA
  \*------------------------------------*/

.wrapper__batches_data {
	width: auto;
  display: grid;
  grid-template-columns: 9% 49% 19% 20%;
	column-gap: 1%;
	height: 50px;
	
	text-align: center;
}

.batches__nom {
  display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  height: 100%;
  border-radius: 5pt;
  border: 1px solid black;
}

.batches__nom_title {
  border-bottom: 1px solid black;
}

.batches__nom_field {
	display: grid;
	place-content: center;
}

.batches__data {
  display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  grid-template-columns: 25% 25% 25% 25%;
	border-radius: 5pt;
	
  border: 1px solid black;
}

.batches__section {
  
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.batches__data_column {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.batches__sup {
  border-bottom: 1px solid black;
}

.batches__section_column {
  border-right: 1px solid black;
	display: grid;
	place-content: center;
}

.batches__data_field {
  border-right: 1px solid black;
	display: grid;
	place-content: center;
}

.batches__sup_column {
	display: grid;
	place-content: center;
}

.batches__payment {
  display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  border-radius: 5pt;
  border: 1px solid black;
}

.batches__payment_form {
  border-bottom: 1px solid black;
  margin-left: -20px;
  font-size: 5px;
  font-weight: bold;
}

.batches__payment_field {
	display: grid;
	place-content: center;
}

.batches_amount {
  display: grid;
  text-align: center;
  grid-template-rows: 15px auto;
  border-radius: 5pt;
  border: 1px solid black;
}

.batches__amount_column {
	display: grid;
	place-content: center;
  border-bottom: 1px solid black;
}

.batches__amount_field {
	display: grid;
	place-content: center;
}

/*------------------------------------*\
      #SECTION-DISCLAIMER DOCUMENT NOTE
  \*------------------------------------*/

.wrapper__disclaimer {
  text-align: start;
  justify-content: start;
  align-items: start;
  height: 20px;
}

/*------------------------------------*\
      #SECTION-LEGAL DISCLAIMER DOCUMENT
  \*------------------------------------*/

.wrapper__legalDisclaimer {
  /* border: 1px solid black; */
  /* border-radius: 5pt; */
  /* margin-bottom: 10px; */
	/* width: 100%; */
	/* display: grid; */
	/* place-content: center; */
	/* padding: 5px; */
}

.wrapper__legalDisclaimer p {
	width: 100%;
	text-align: justify;
	margin: 0px;
}

/*------------------------------------*\
      #SECTION-AMOUNT DETAILS TABLE 
  \*------------------------------------*/

.wrapper__table_details {
  display: grid;
	grid-template-columns: 12% 22% 22% 22% 22%;
  grid-template-rows: auto;
	border-radius: 5pt 5pt 0 0;
	text-align: center;
  border: 1px solid black;
}

.wrapper__table_details .table__column {
	height: 20px;
	width: 100%;
	border-right: 1px solid black;
	display: grid;
	place-content: center;
}

.wrapper__table_details .table__total {
	height: 20px;
	width: auto;
	display: grid;
	place-content: center;
}

.wrapper__table_fields {
	width: 100%;
  border-left: 1px solid black;
	border-right: 1px solid black;
	min-height: 40vh;
}

.wrapper__table_fields__row {
	width: 100%;
  display: grid;
	grid-template-columns: 12% 22% 22% 22% 22%;
	text-align: center;
}

.table__field {
	height: 20px;
  width: 100%;
	border-right: 1px solid black;
	display: grid;
	place-content: center;
}

.table__columns {
	height: 20px;
  width: 100%;
  border-right: 1px solid black;
	display: grid;
	place-content: center;
}

.table__fields {
	height: 20px;
  width: 100%;
	display: grid;
	place-content: center;
}

/*------------------------------------*\
      #SECTION-AMOUNT DETAILS
  \*------------------------------------*/

.wrapper__amount {
  border: 1px solid black;
  border-radius: 0 0 5pt 5pt;
  margin-bottom: 10px;
	width: 100%;
	padding: 5px;
}

.wrapper__amount p {
  width: 100%;
	text-align: justify;
	margin: 0px;
	font-family: 'Times New Roman', Times, serif;
}
/*------------------------------------*\
      #SECTION-LAST NOTE 
  \*------------------------------------*/

.wrapper__note {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 0px;
  text-align: justify;
	width: 95%;
}
