/*------------------------------------*\
          #MAIN-WRAPPER
  \*------------------------------------*/

.mainwrapper {
  display: grid;
  width: 798px;
  border-radius: 5pt;
  padding-bottom: 100px;
  position: relative;
  font-size: xx-small;
  padding: 40px;
}

/*------------------------------------*\
          #SECTION-HEADER
   \*------------------------------------*/

.wrapper__header {
  display: grid;
  grid-template-columns: 10% 70% 15%;
  height: auto;
  margin-bottom: 10px;
}

.logo {
  display: grid;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-image: url('/home/fixter/Lomas-Cocoyoc-frontend/src/assets/images/SPU_logo.svg');
}

.header h1 {
  font-size: 24px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 2px;
}

.header p {
  text-align: center;
  font-size: 10px;
  line-height: 5px;
}

.receipt__number {
  display: grid;
  text-align: center;
  grid-template-rows: 20% 80%;
  border: 1px solid black;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

.receipt__number_field {
  border-top: 1px solid black;
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

/*------------------------------------*\
          #SECTION-USER NAME AND DATE
  \*------------------------------------*/

.wrapper__user_date {
  display: grid;
  grid-template-columns: 79% 15%;
  margin-bottom: 10px;
  grid-row-gap: 1em;
  grid-column-gap: 0.8em;
  height: auto;
}

.user__field {
  display: grid;
  text-align: start;
  justify-content: start;
  align-items: start;
  border: 1px solid black;
  border-radius: 5pt;
  height: 50px;
}

.user__field p {
  margin-top: 2px;
  margin-left: 2px;
}

.date {
  height: auto;
  border: 1px solid black;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

.date__container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
}

.date__day {
  display: grid;
  border-bottom-left-radius: 5pt;
  height: 40px;
  border-top: 1px solid black;
}

.date__month {
  display: grid;
  height: 40px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.date__year {
  display: grid;
  border-bottom-right-radius: 5pt;
  height: 40px;
  border-top: 1px solid black;
}

/*------------------------------------*\
          #SECTION-BATCHES DATA
  \*------------------------------------*/

.wrapper__batches_data {
  display: grid;
  grid-template-columns: 14% 40% 20% 20%;
  grid-column-gap: 0.3em;
}

.batches__nom {
  display: grid;
  grid-template-rows: 25% 75%;
  grid-template-columns: auto;
  height: 50px;
  border-radius: 5pt;
}

.batches__nom_title {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
}

.batches__nom_field {
  border: 1px solid black;
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

.batches__data {
  display: grid;
  grid-template-rows: 25% 75%;
  grid-template-columns: 25% 25% 25% 25%;
  border-radius: 5pt;
}

.batches__section {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top-left-radius: 5pt;
}

.batches__data_column {
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.batches__sup {
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-top-right-radius: 5pt;
}

.batches__section_column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-bottom-left-radius: 5pt;
}

.batches__data_field {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.batches__sup_column {
  border-bottom-right-radius: 5pt;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.batches__payment {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 25% 75%;
  border-radius: 5pt;
}

.batches__payment_form {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
}

.batches__payment_field {
  border: 1px solid black;
  border-bottom-right-radius: 5pt;
  border-bottom-left-radius: 5pt;
}

.batches_amount {
  display: grid;
  border-radius: 5pt;
  grid-template-rows: 25% 75%;
  grid-template-columns: auto;
}

.batches__amount_column {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
}

.batches__amount_field {
  border: 1px solid black;
  border-bottom-right-radius: 5pt;
  border-bottom-left-radius: 5pt;
}

/*------------------------------------*\
      #SECTION-DISCLAIMER DOCUMENT NOTE
  \*------------------------------------*/

.wrapper__disclaimer {
  text-align: start;
  justify-content: start;
  align-items: start;
  height: 20px;
}

/*------------------------------------*\
      #SECTION-LEGAL DISCLAIMER DOCUMENT
  \*------------------------------------*/

.wrapper__legalDisclaimer {
  border: 1px solid black;
  border-radius: 5pt;
  margin-bottom: 10px;
  width: 95%;
}

.wrapper__legalDisclaimer p {
  text-align: justify;
  margin-left: 2px;
  margin-right: 2px;
}

/*------------------------------------*\
      #SECTION-AMOUNT DETAILS TABLE 
  \*------------------------------------*/

.wrapper__table_details {
  display: grid;
  grid-template-columns: 10% 25.5% 20% 20% 20%;
  grid-template-rows: auto;
  border-top-left-radius: 5pt;
}

.table__fee {
  height: 20px;
  border-top-left-radius: 5pt;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.table__column {
  height: 20px;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.table__total {
  border-top-right-radius: 5pt;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.wrapper__table_fields {
  display: grid;
  grid-template-columns: 10% 25.5% 20% 20% 20%;
  grid-template-rows: auto;
  border-top-right-radius: 5pt;
  border-top-left-radius: 5pt;
}

.table__field {
  border-top: 1px solid black;
  border-left: 1px solid black;
  width: auto;
}

.table__columns {
  height: 400px;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.table__fields {
  height: 400px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

/*------------------------------------*\
      #SECTION-AMOUNT DETAILS
  \*------------------------------------*/

.wrapper__amount {
  height: 50px;
  width: 95.2%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 0px;
  text-align: start;
  border: 1px solid black;
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

.wrapper__amount p {
  margin-top: 2px;
  margin-left: 2px;
}
/*------------------------------------*\
      #SECTION-LAST NOTE 
  \*------------------------------------*/

.wrapper__note {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 0px;
  text-align: justify;
  width: 95%;
}
