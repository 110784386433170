.ant-form-item-control-input-content .ant-input {
  font-size: 14px;
}

.ant-form-item .ant-form-item-label label {
  font-size: 16px;
  color: #828282;
}

.ant-form-item .ant-form-item-explain div {
  font-size: 14px;
}

.ant-form-item .ant-form-item-control-input-content .ant-input-disabled,
.ant-form-item .ant-form-item-control-input-content .ant-input-number-disabled,
.ant-form-item .ant-form-item-control-input-content .ant-select-disabled .ant-select-selector {
  background-color: #ffffff !important;
  color: var(--coal) !important;
}

.ant-form-item .ant-form-item-control-input-content .ant-select .ant-select-arrow .anticon svg {
  color: #595959;
}

.ant-form .c_label .ant-form-item-label label {
  font-weight: bold;
  color: #252525;
}

.ant-form .c_label .ant-form-item-label {
  padding: 0px;
}

.ant-modal .ant-modal-content .ant-modal-title {
  font-weight: bold;
  font-size: 24px;
}

.ant-modal .ant-modal-content .ant-modal-close-x svg {
  color: #323232;
}

.ant-modal .ant-modal-footer button {
  border-radius: 0px;
  width: 105px;
}

.ant-table-tbody .ant-table-cell {
  font-size: 12px;
}
.ant-table-pagination.ant-pagination-left{
  justify-content: flex-start;
}
 /*------------------------------------*\
  #Tag section
\*------------------------------------*/
.ant-tag .ant-tag-green {
  background: #95BF48;
  border: #95BF48;
  border-radius: 4px;
  color: white;
}

 /*------------------------------------*\
  #Batches section
\*------------------------------------*/


.batches-table .ant-table-thead > tr > th {
  font-weight: bold;
  padding: 16px !important;
}
.ant-table-tbody > tr > td{
  padding: 16px !important;
}
.debt{
  font-weight: bold;
}

 /*------------------------------------*\
  #Circle button section
\*------------------------------------*/
.seeker .ant-btn {
  border-color: transparent;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 8px rgba(196, 196, 196, 0.25);
}

.seeker .ant-popover-inner {
  border-radius: 8px;
}

@primary-color: #95BF48;@background-color-base: #C4C4C4;@border-radius-base: 2px;@font-family: Avenir;@link-hover-color: #84AA3F;@link-decoration: underline;@disabled-color: #FFFFFF;@disabled-color-dark: @background-color-base;@btn-font-weight: 500;@font-size-base: 16px;@form-item-label-font-size: 15px;@menu-bg: #323232;@menu-item-active-bg: rgba(149, 191, 72, 0.2);@menu-item-color: #FFFFFF;@table-header-bg: transparent;@btn-border-radius-base: 16px;@input-icon-color: #323232;@card-radius: 8px;