.link span {
  color: var(--blueAnt);
  text-decoration: underline;
}

.item {
  margin: 8px 0px;
}

.item__value__main {
  color: var(--primary);
  font-weight: bold;
}

.item__title {
  color: var(--coal);
  margin-right: 10px;
}

.tabs {
  margin: 0px 30px;
}