/*------------------------------------*\
  #Divider styles
\*------------------------------------*/
.divider-h {
  border-top: 1px solid var(--borderColor);
  margin-bottom: 32px;
}

.divider-v {
  border-left: 1px solid var(--borderColor);
  margin: 0 16px;
  width: 0px;
  top: 0;
  bottom: 0;
}