.statusbar {
	width: auto;
  display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.statusbar__right {
	display: flex;
}

.statusbar__center-content {
  align-self: center;
}

.statusbar__username {
  margin-right: 16px;
}