/*------------------------------------*\
        #SECTION-SIGN-SPLIT-SCREEN
\*------------------------------------*/
.sign {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sign__left {
  width: 100%;
  height: 100%;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.sign__right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .sign {
    grid-template-columns: 0px 1fr;
  }
}
