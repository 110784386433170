/*------------------------------------*\
  #general style
\*------------------------------------*/

.cashier {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  background-color: var(--backgroundLight);
}

.cashier__content {
  flex-grow: 1;
  padding: 0px 44px 42px 44px;
  box-sizing: border-box;
  margin-left: var(--atmSidebarWidth);
}

.title {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 66px;
  /* identical to box height */
  color: #323232;
}

/*------------------------------------*\
  #column style
\*------------------------------------*/

.paid {
  background-color: white;
  color: #95bf48;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.pending {
  background-color: white;
  color: #ffa800;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.topContainer {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
  column-gap: normal;
  margin-bottom: 20px;
}

.batchesInformation {
  display: grid;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  padding: 10px;
}

.batchesInformation_title {
  display: grid;
  background: #ffffff;
  justify-content: start;
}

.messagesTable {
  position: relative;
  width: auto;
  height: 262px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
}

.messages_header {
  display: grid;
  grid-template-columns: 40% 55%;
  background: #ffffff;
  justify-content: start;
  padding-left: 10px;
  padding-top: 10px;
}

.header_table {
  background-color: #f2f2f2 !important;
}

.link_view {
  display: grid;
  justify-content: right;
}

.date {
  position: absolute;
  width: 120px;
  height: 15px;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #828282;
}

.usernum {
  position: absolute;
  width: 80px;
  height: 15px;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #828282;
}

.message {
  position: relative;
  top: 12px;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #828282;
  text-align: justify;
}

.actions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 0.2rem;
}

.actions span:hover {
	cursor: pointer;
}