/*------------------------------------*\
          #SECTION-PROFILE
  \*------------------------------------*/
.footer__form {
  margin: 32px 0px 20px 0px;
  padding: 0px 40px;
}

.footer__form .btn__footer {
  padding: 0px 40px;
}

.footer__form .text__default span {
	color: var(--coal);
	transition: all 0.3s;
}

.footer__form .text__default:hover span {
	color: var(--primary);
}