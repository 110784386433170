/*------------------------------------*\
        #SECTION-TICKET-STYLES
\*------------------------------------*/
.ticket {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 28px 17px rgba(204, 204, 204, 0.25);
  border-radius: 4px 4px 0 0;
  padding: 16px 24px;
  margin: 0 4px var(--space-lg) 4px;
  position: relative;
}

.ticket__polygon {
  background-position: center;
  width: 100%;
  height: 20px;
  position: absolute;
  left: 0px;
  bottom: -20px;
}

.ticket__footer {
  width: 100%;
  text-align: end;
  margin-top: 10px;
  color: var(--coal);
  display: flex;
  flex-direction: column;
}

.ticket__money {
  margin-left: var(--space-lg);
  color: var(--jet);
}