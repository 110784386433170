.invoicetype {
  max-width: 352px;
  min-height: 410px;
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: 40px;
}

.asset {
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 62px;
}

.download {
  position: absolute!important;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.button {
  bottom: -32px;
  max-width: 186px;
  align-self: center;
}
