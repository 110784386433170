/*------------------------------------*\
          #SECTION-DEBTS-CARD
\*------------------------------------*/

.debts__footer {
  width: 100%;
  margin-top: var(--space-sm);
  text-align: end;
  font-weight: bold;
  font-size: 11px;
}

.debts__footer__money {
  font-weight: normal;
  margin-left: var(--space-sm);
}
