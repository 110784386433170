.container {
  display: flex;
  flex-flow: column;
}

.addinvoice {
  width: 80%;
  margin:0 auto;
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addinvoice p {
  max-width: 600px;
  text-align: center;
}
