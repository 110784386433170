.row__table__row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.row__table__label {
  flex: 2;
  font-size: 16px;
  font-weight: normal;
  color: var(--textColor);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100px;
  width: 100%;
}

.row__table__amount {
  flex: 1;
  font-size: 16px;
  font-weight: 800;
  color: var(--textColor);
  text-align: right;
}

.row__table__divider {
  width: 1px;
  height: 30px;
  border-left: 1px solid var(--borderColor);
  margin: 0 40px;
}
