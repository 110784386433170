/*------------------------------------*\
      #SECTION-INVOICES-REGISTER
\*------------------------------------*/
.container_card {
  width: 100%;
  height: calc(100vh - 260px);
  background-color: #fff;
  flex: 1;
  box-shadow: 0px 5px 10px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
  padding: 16px 24px;
  margin: 0 4px 0 4px;
  overflow: auto;
}

.container__copy {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 25%;
}

.container__footer__content {
  font-size: 12px;
  margin: 0px;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.container__footer__text {
  font-weight: bold;
  margin: 0px;
  margin-right: var(--space-xlg);
}

.table {
  position: relative;
}

.table__actions {
  width: 100%;
  margin-top: 30px;
  display: grid;
  place-content: center;
}
